import React from 'react';

import Logo from '../img/logo.png';
import { clickEvent } from '../common/gtag';

const Footer = () => {
    const clickHandler = () => {
        clickEvent('clicked_ceo_link');

        window.open(
            'https://www.notion.so/c67627c0e4d44146839776ff677c514d',
            '_blank'
        ).opener = null;
    };


    const renderQuote = () => {
        const quotes = [
            {
                by: 'マークザッカーバーグ',
                text: 'DONE IS BETTER THAN PERFECT！（完璧を目指すよりまず終わらせろ）'
            },
            {
                by: 'パスカル',
                text:
                    '人間は自然のうちで、最も弱い一茎の葦にすぎない。だが、それは『考える葦（un roseau pensant）』である。'
            },
            {
                by: 'アインシュタイン',
                text: '人生とは自転車のようなものだ。 倒れないようにするには走らなければならない。'
            },
            {
                by: 'スティーブ・ジョブズ',
                text: '最も重要な決定とは、何をするかではなく、何をしないかを決めることだ。'
            },
            {
                by: 'スティーブ・ジョブズ',
                text: 'Stay hungry. Stay foolish.'
            }
        ];
        const { by, text } = quotes[Math.floor(Math.random() * quotes.length)];

        return (
            <>
                {text}
                <br />
                <span style={{ fontSize: '0.9rem' }}>by {by}</span>
            </>
        );
    };

    const renderSocials = () => {
        const socials = [
            { name: 'Github', icon: 'ti-github', url: 'https://github.com/genk1' },
            {
                name: 'LinkedIn',
                icon: 'ti-linkedin',
                url: 'https://www.linkedin.com/in/genkia/'
            },
            {
                name: 'Facebook',
                icon: 'ti-facebook',
                url: 'https://www.facebook.com/genkiamagata/'
            },
            // {
            //     name: 'Youtube',
            //     icon: 'ti-youtube',
            //     url: 'https://www.youtube.com/channel/UCXENW_FzHNHbu2T95NLRg-w'
            // },
            {
                name: 'Instagram',
                icon: 'ti-instagram',
                url: 'https://www.instagram.com/amachan0904/'
            },
            { name: 'Twitter', icon: 'ti-twitter', url: 'https://twitter.com/GenkiAma' }
        ];

        return socials.map(social => (
            <a
                target="_blank"
                href={social.url}
                key={social.icon}
                title={social.name}
                rel="noopener noreferrer"
                className="footer__social-btn">
                <i className={`${social.icon} footer__social-icon`} />
            </a>
        ));
    };

    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer">
            <img src={Logo} alt="Kenneth Kwakye-Gyamfi Logo" className="footer__logo" />

            <p className="footer__quote">{renderQuote()}</p>

            <div className="footer__social">{renderSocials()}</div>

            <div className="footer__link" onClick={clickHandler}>
                <span className="footer__link-btn">代表の経歴をみる</span>
            </div>

            <p className="footer__copyright">
                (21年夏 起業予定)株式会社AC (AC Inc.) &copy; {currentYear}
            </p>
        </footer>
    );
};

export default Footer;
