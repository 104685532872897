import React from 'react';

import HeroFooter from '../img/hero.svg';
import Logo from '../img/logo.jpg';
import { clickEvent } from '../common/gtag';

const Hero = () => (
    <div className="hero">
        <nav className="navbar grid grid--padded">
            <a href="/" className="navbar__logo">
                <img src={Logo} alt="Kenneth Kwakye-Gyamfi Logo" className="navbar__logo-img" />
            </a>
        </nav>

        <div className="hero__body">
            <h1 className="hero__title">
                奄美 x 暮らす x Technology
                <br />
                <span style={{ fontSize: '1rem', color: '#555' }}>
                    (2021年夏 起業予定)　
                    <br />
                    株式会社AC (Ama Class Inc.)
                </span>
            </h1>

            <p className="hero__subtitle">
                奄美でWEBに関しての
                <span className="hero__subtitle__bigger">悩みや疑問</span>
                があったらまず相談してみてください！
            </p>

            {/* <img src={Memoji} alt="Kenneth Kwakye-Gyamfi memoji" className="hero__memoji" /> */}
            <div className="contact-button-wrap">
                <a
                    onClick={() => clickEvent('clicked_phone_link')}
                    href="tel: 08092920232"
                    className="btn btn__call">
                    電話する
                </a>
                <a
                    onClick={() => clickEvent('clicked_form_link')}
                    href="#contact-form"
                    className="btn btn__call">
                    問い合わせる
                </a>
            </div>
            <div className="hero__footer">
                <img src={HeroFooter} className="hero__footer-img" alt="ヒーロー画像" />
            </div>
        </div>
    </div>
);

export default React.memo(Hero);
