import React from 'react';

import { clickEvent } from '../common/gtag';

const Explain = () => {
    const clickHandler = () => {
        clickEvent('clicked_notion_link');

        window.open(
            'https://www.notion.so/ac2580a0898b4b68ac25f8830d07edb5',
            '_blank'
        ).opener = null;
    };
    return (
        <div className="works">
            <h2 className="works__title">サイト公開に関して無料相談受け付け中！</h2>
            <p className="works__subtext">
                サイト一つを立ち上げる、ECサイトを立ち上げるだけで、
                <strong className="strongword">やらなければいけないこと</strong>はたくさんあります。
                <br />
                <br />
                ドメイン取得からサーバー契約などの事前準備から実際にサイトを公開してからのGoogleやその他サービスへの登録などを含めると
                <strong className="strongword">やることリストが20を超えます！</strong>
                <br />
                <br />
                また実際にサイトを立ち上げても、分析を行わなければ頑張って作ったサイトも意味がなくなってきてしまいます。
                <br />
                <br />
                ご自身でやっていくにしてもなにが必要なのかなど気軽に相談してみてください。
            </p>
            <div style={{ textAlign: 'center' }} onClick={clickHandler}>
                <span className="works__link">やることリストを確認する</span>
            </div>
        </div>
    );
};

export default React.memo(Explain);
