import React from 'react';

import Design from '../img/computer.svg';
import MobileApp from '../img/mobile-app.svg';
import WebApp from '../img/development.svg';

const About = () => {
    const description =
        '(2021年夏 起業予定)株式会社AC (アマクラス)は、奄美の暮らしをよりよいものにするために立ち上げました。移住者や島民の方が以下に生活がしやすくなるかを考えた自社サービス作成や、経営者への業務支援システムの開発など多岐にわたり活躍しております。';

    const renderBreakdowns = () => {
        const breakdowns = [
            {
                img: WebApp,
                title: 'Webサイト・Webアプリの開発',
                description:
                    'シンプルなホームページのようなWebサイトからECシステム、複雑なアプリなども作成することが可能です。立ち上げの際のコンセプト確認から一緒にやらせていただきます。',
                listTitle: '作りたいものを届けます',
                list: [
                    'ホームページの作成',
                    'Wordpress運用',
                    'Shopify開発',
                    'Webアプリ開発',
                    'モバイルアプリ開発',
                    'etc...'
                ]
            },
            {
                img: Design,
                title: 'Webにおける事業改善',
                description:
                    'ホームページの作成、インスタやYoutubeのようなSNSの作成、ECサイトの構築やドメイン設定やGOOGLE検索周りの設定。事業を立ち上げるときに必要な工程・課題を一緒に解決します。',
                listTitle: 'IT周りでの悩み全てお助けします',
                list: [
                    'Webページの解析',
                    'SNSアカウント運用',
                    '動画撮影・編集',
                    'Web広告支援',
                    'ECサイトの構築',
                    '内地向けの広告戦略',
                    'etc...'
                ]
            },
            {
                img: MobileApp,
                title: 'DXの導入・業務円滑化支援',
                description:
                    '日々の業務に同じことを毎日するようなことがあれば、自動化を行うことで年間数日分の作業時間を減らせることができるかもしれません！もし日々のルーティン業務に課題を抱えていましたら一度相談ください。',
                listTitle: 'IT技術でストレスから開放します',
                list: [
                    '業務円滑化支援',
                    'Webシステムの見直し',
                    'Web費用周りの見直し',
                    'メールリストなどの作成',
                    'etc...'
                ]
            }
        ];

        return breakdowns.map(breakdown => (
            <div className="about__breakdown col" key={breakdown.title.toLowerCase()}>
                <img src={breakdown.img} alt={breakdown.title} className="about__breakdown-img" />

                <h3 className="about__breakdown-title">{breakdown.title}</h3>
                <p className="about__breakdown-desc">{breakdown.description}</p>

                <p className="about__breakdown-subtitle">{breakdown.listTitle}</p>
                <ul className="about__breakdown-list">
                    {breakdown.list.map(elem => (
                        <li key={elem} className="about__breakdown-elem">
                            {elem}
                        </li>
                    ))}
                </ul>
            </div>
        ));
    };

    return (
        <div className="about">
            <div className="about__inner">
                <h2 className="about__title">アマクラスの立ち上げ 👋🏼</h2>
                <p className="about__text">{description}</p>
            </div>

            <div className="about__breakdowns grid grid--padded">{renderBreakdowns()}</div>
        </div>
    );
};

export default React.memo(About);
