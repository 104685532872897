import React from 'react';

// Change this if you want to update the form name in Netlify
const NETLIFY_FORM_NAME = 'contact';

export default function ContactForm() {
    return (
        <form
            method="post"
            data-netlify="true"
            name={NETLIFY_FORM_NAME}
            className="contact-form grid"
            data-netlify-honeypot="bot-field">
            <input type="hidden" name="form-name" value={NETLIFY_FORM_NAME} />
            <div className="contact-form__container">
                <label htmlFor="name" className="contact-form__label">
                    お名前*
                </label>
                <input type="text" id="name" name="name" required className="contact-form__input" />
            </div>
            <div className="contact-form__container">
                <label htmlFor="company" className="contact-form__label">
                    会社名
                </label>
                <input type="text" id="company" name="company" className="contact-form__input" />
            </div>
            <div className="contact-form__container">
                <label htmlFor="tel" className="contact-form__label">
                    お電話番号*
                </label>
                <input type="text" required id="tel" name="tel" className="contact-form__input" />
            </div>
            <div className="contact-form__container">
                <label htmlFor="email" className="contact-form__label">
                    メールアドレス*
                </label>
                <input
                    type="email"
                    required
                    id="email"
                    name="email"
                    className="contact-form__input"
                />
            </div>
            <div className="contact-form__container contact-form__container--textarea">
                <label htmlFor="description" className="contact-form__label">
                    問い合わせ内容
                </label>
                <textarea
                    id="description"
                    required
                    name="description"
                    className="contact-form__textarea"
                />
            </div>
            <button type="submit" className="contact-form__submit">
                送信
            </button>
        </form>
    );
}
