import React from 'react';

import ContactForm from './ContactForm';

const Contact = () => (
    <div id="contact-form" className="contact grid grid--padded">
        <h2 className="contact__title">悩みを解決します！</h2>

        <p className="contact__subtitle">
            創業記念キャンペーン中！！まずはお気軽にご相談ください！
        </p>

        <ContactForm />
    </div>
);

export default Contact;
